export function toInitials(...names: string[]): string | undefined {
  return names
    .join(' ')
    .replace('(deleted)', '')
    .replace(/\s+/g, ' ')
    .split(' ')
    .reduce((initials, word) => initials + word.charAt(0), '')
    .toUpperCase()
    .substring(0, 3)
}

export function randomString(length = 10): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  const charactersLength = characters.length

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export function toBoolean(value: string | boolean | null | number | undefined): boolean {
  return ![undefined, null, false, 0, '', 'false', '0'].includes(value)
}

export function stringSnakeToCapitalize(value: string) {
  return value
    .replaceAll('_', ' ')
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export function capitalizeFirstLetter(value: string) {
  if (typeof value !== 'string') return ''

  return value.charAt(0).toUpperCase() + value.slice(1, value.length)
}
