import { notification } from 'ant-design-vue'
import { kebabCase } from 'lodash-es'

// do not duplicate notifications with the same message by generating key from message
const getKey = (message: string) => {
  if (message.length === 0) {
    return 'empty-notification'
  }

  return kebabCase(message.replace(/[^a-zA-Z0-9 ]/g, '')).slice(0, 30)
}

notification.config({
  maxCount: 5,
  placement: 'bottomRight',
})

export function notifySuccess(message: string): void {
  notification.success({
    key: getKey(message),
    message: 'Success',
    description: message,
  })
}

export function notifyError(message: string): void {
  notification.error({
    key: getKey(message),
    message: 'Error',
    description: message,
  })
}

export function notifyWarning(message: string): void {
  notification.warning({
    key: getKey(message),
    message: 'Warning',
    description: message,
  })
}

export function notifyInfo(message: string): void {
  notification.info({
    key: getKey(message),
    message: 'Info',
    description: message,
  })
}
