import store from '@/store'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Locale } from '@/shared'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(duration)

// Also separate between long/short dates and time
export function getBusinessTimezone(): string {
  return store.getters['DashboardModule/timezone']
}

export function getLocale(): string {
  return store.getters['DashboardModule/countryLocale']?.locale.replace('_', '-') ?? Locale.US
}

export function toLocalTime(time: string | undefined): string {
  if (time === undefined) {
    return ''
  }

  const [hours, minutes] = time.split(':')
  const date = new Date()
  date.setUTCHours(Number(hours))
  date.setUTCMinutes(Number(minutes))
  date.setUTCSeconds(0)
  date.setUTCMilliseconds(0)

  return shortTime(dayjs(date).format()) ?? ''
}

export function validDateRange(dateRange?: [Date, Date]): [Date, Date] {
  const today = new Date()

  return dateRange ?? [new Date(1900, 0, 1), new Date(today.getFullYear() + 1, 0, 1)]
}

export function dateRangeToOneYearPastFromToday(): [Date, Date] {
  const today = new Date()
  const dateRange = <[Date, Date]>[new Date(1900, 0, 1), new Date(today.getFullYear() + 1, 0, 1)]
  return validDateRange(dateRange)
}

export function dateRangeToToday(): [Date, Date] {
  const today = new Date()
  const dateRange = <[Date, Date]>[new Date(1900, 0, 1), new Date(today)]
  return validDateRange(dateRange)
}

export function formatTimeToUTC(
  datetime: string | number | Date,
  timezone = getBusinessTimezone(),
) {
  return dayjs.tz(datetime, timezone).utc().toISOString()
}

export function formatDateToTimezone(
  datetime: string,
  timezone = getBusinessTimezone(),
  dateFormat = getServerDateFormat(),
) {
  return dayjs(datetime).tz(timezone).format(dateFormat)
}

export function getDateRange(start: string, end: string, disabledWeekdays?: number[]): string[] {
  let dates: Date[] = []

  for (const sDate = new Date(start); sDate <= new Date(end); sDate.setDate(sDate.getDate() + 1)) {
    dates.push(new Date(sDate))
  }

  const filteredDates = dates.filter((date) => disabledWeekdays?.includes(date.getDay()))
  dates = filteredDates

  return dates.map((date) => dayjs(date).format(getServerDateFormat(ServerDateFormat.Date)))
}

// Functions from useDate
export const enum ServerDateFormat {
  Date = 'date',
  DateTime = 'datetime',
  Time = 'time',
  TimeWithoutSeconds = 'timewithoutseconds',
}

// server accepts this format, so send it like this
export function getServerDateFormat(type?: ServerDateFormat) {
  switch (type) {
    case ServerDateFormat.Date:
      return 'YYYY-MM-DD'
    case ServerDateFormat.DateTime:
      return 'YYYY-MM-DD HH:mm:ss'
    case ServerDateFormat.Time:
      return 'HH:mm:ss'
    case ServerDateFormat.TimeWithoutSeconds:
      return 'HH:mm'
    default:
      return 'YYYY-MM-DD HH:mm:ss'
  }
}

export function convertUtcToTimezone(date?: string, timezone = getBusinessTimezone()): string {
  if (date === undefined) {
    return ''
  }

  return dayjs.utc(date).tz(timezone).format(getServerDateFormat())
}

export function convertTimezoneToUtc(date?: string, timezone = getBusinessTimezone()): string {
  if (date === undefined) {
    return ''
  }

  return dayjs.tz(date, timezone).utc().format(getServerDateFormat())
}

export function convertDateTimeToUtc(date?: string): string {
  if (date === undefined) {
    return ''
  }

  return dayjs.utc(date).format()
}

export function convertDateTimeToDate(
  date: string,
  dateFormat = getServerDateFormat(ServerDateFormat.Date),
): string {
  if (date === undefined) {
    return ''
  }

  return dayjs(date).format(dateFormat)
}

export const getDateFormatFromLocale = (
  dateFormat = DateTimeFormat.Short,
  locale = getLocale(),
) => {
  const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
    switch (part.type) {
      case 'weekday':
        return dateFormat === DateTimeFormat.Full ? 'dddd' : ''
      case 'day':
        return 'D'.repeat(part.value.length)
      case 'month':
        return dateFormat === DateTimeFormat.Full ? 'MMMM' : 'M'.repeat(part.value.length)
      case 'year':
        return dateFormat === DateTimeFormat.Full ? '' : 'Y'.repeat(part.value.length)
      case 'literal':
        return part.value
      default:
        return 'M/D/YY'
    }
  }

  return new Intl.DateTimeFormat(locale, { dateStyle: dateFormat })
    .formatToParts(new Date('2025-01-01'))
    .map(getPatternForPart)
    .join('')
}

export const getTimeFormatFromLocale = (
  timeFormat = DateTimeFormat.Short,
  locale = getLocale(),
) => {
  const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
    switch (part.type) {
      case 'hour':
        return part.value.length > 1 ? 'H'.repeat(part.value.length) : 'h'
      case 'minute':
        return 'm'.repeat(part.value.length)
      case 'second':
        return 's'.repeat(part.value.length)
      case 'dayPeriod':
        return 'A'
      case 'literal':
        return part.value
      default:
        return 'h:mm A'
    }
  }

  return new Intl.DateTimeFormat(locale, { timeStyle: timeFormat })
    .formatToParts(new Date('2025-01-01T13:34:56'))
    .map(getPatternForPart)
    .join('')
}

export function getDateTimeFormatFromLocale() {
  return getDateFormatFromLocale() + ' ' + getTimeFormatFromLocale()
}

// TODO Review:
// - All server dates above and figure out how to reduce their various formats

// Dates can be separated by their use:
// - Dates displayed for users on the website, which should be localized (adjusted to locale format (US, UK, Australian dates, etc.)), but not necessarily adjusted to timezone
// - Dates sent to the server (various formats), but usually should be zulu time
// - Dates provided to various plugins and packages (various formats)

// USER DATES START

// New date formats using internationalization, so after providing a locale we have each different country's dates in their local format
// The dates and times can be formatted in 4 different types. Check DateTimeFormat enum

export const enum DateTimeFormat {
  Full = 'full',
  Long = 'long',
  Medium = 'medium',
  Short = 'short',
}

type genericIntlDateOptions = {
  datetime: string // Provided date
  dateOnly: boolean // If only date, then do not display time
  timeOnly: boolean // Return only time from the date
  useTimezone: boolean // If timezone should be used at all
  dateStyle: DateTimeFormat // We mainly use short date style
  timeStyle: DateTimeFormat // We mainly use short time style
  locale: string // Locale from dashboard store locale
  timezone: string // If timezone not provided and it shouldn't be used, then use 'UTC'. Otherwise, the fn uses user's local timezone provided by the browser.
}

type genericIntlDateTimeOptions = {
  datetime: string
  dateStyle: DateTimeFormat
  timeStyle: DateTimeFormat
  locale: string
  timezone: string
}

type genericDateTimeWithoutTzOptions = {
  datetime: string
  dateStyle: DateTimeFormat
  timeStyle: DateTimeFormat
  locale: string
}

// Generic dates localized for each country by providing a locale

export function genericIntlDate(options: genericIntlDateOptions) {
  // Validate if the date is actually valid or if not valid date is provided 'new Date' fn will fail.
  if (!options.datetime || !dayjs(options.datetime).isValid()) {
    return
  }

  const date = new Date(options.datetime)

  return new Intl.DateTimeFormat(options.locale, {
    dateStyle: options.timeOnly ? undefined : options.dateStyle,
    timeStyle: options.dateOnly ? undefined : options.timeStyle,
    timeZone: options.timezone,
  }).format(date)
}

// Format DateTime with timezone
export function genericIntlDateTime(options: genericIntlDateTimeOptions) {
  if (!options.datetime || !dayjs(options.datetime).isValid()) {
    return
  }

  const date = new Date(convertDateTimeToUtc(options.datetime))

  return new Intl.DateTimeFormat(options.locale, {
    dateStyle: options.dateStyle,
    timeStyle: options.timeStyle,
    timeZone: options.timezone,
  }).format(date)
}

// Format DateTime
export function genericDateTimeWithoutTz(options: genericDateTimeWithoutTzOptions) {
  if (!options.datetime || !dayjs(options.datetime).isValid()) {
    return
  }

  const date = new Date(options.datetime)

  return new Intl.DateTimeFormat(options.locale, {
    dateStyle: options.dateStyle,
    timeStyle: options.timeStyle,
    timeZone: 'UTC',
  }).format(date)
}

// Date function names are comprised of:
// - Whether the date is converted to timezone (local)
// - Formatting type of the date (full, long, etc., look at DateTimeFormat for variations)
// - Localization country code in ISO2 format (US, GB, AU, LT, etc.)
// - Is it the date or dateTime

// Generate localized dates only, no time
export function localShortDate(
  date: string,
  timezone = getBusinessTimezone(),
  locale = getLocale(),
): string | undefined {
  return genericIntlDate({
    datetime: date,
    dateOnly: true,
    timeOnly: false,
    useTimezone: true,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: locale,
    timezone: timezone,
  })
}

// Generate localized dates only, no time and timezone used
export function shortDate(date: string, locale = getLocale()): string | undefined {
  return genericIntlDate({
    datetime: date,
    dateOnly: true,
    timeOnly: false,
    useTimezone: false,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: locale,
    timezone: 'UTC',
  })
}

// Generate localized dates with time using timezone
export function localShortDateTime(
  date: string,
  timezone = getBusinessTimezone(),
  locale = getLocale(),
): string | undefined {
  return genericIntlDateTime({
    datetime: date,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: locale,
    timezone: timezone,
  })
}

export function shortDateTime(date: string, locale = getLocale()): string | undefined {
  return genericDateTimeWithoutTz({
    datetime: date,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: locale,
  })
}

export function localShortTime(
  date: string,
  timezone = getBusinessTimezone(),
  locale = getLocale(),
): string | undefined {
  return genericIntlDate({
    datetime: date,
    dateOnly: false,
    timeOnly: true,
    useTimezone: true,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: locale,
    timezone: timezone,
  })
}

export function shortTime(date: string, locale = getLocale()): string | undefined {
  return genericIntlDate({
    datetime: date,
    dateOnly: false,
    timeOnly: true,
    useTimezone: false,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: locale,
    timezone: 'UTC',
  })
}

export function shortUsDateTime(date: string, timezone?: string): string | undefined {
  return genericIntlDate({
    datetime: date,
    dateOnly: false,
    timeOnly: false,
    useTimezone: true,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: 'en-US',
    timezone: timezone ? timezone : 'UTC',
  })
}

export function shortUsDate(date: string, timezone?: string): string | undefined {
  return genericIntlDate({
    datetime: date,
    dateOnly: true,
    timeOnly: false,
    useTimezone: true,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: 'en-US',
    timezone: timezone ? timezone : 'UTC',
  })
}

export function shortUsTime(date: string, timezone?: string): string | undefined {
  return genericIntlDate({
    datetime: date,
    dateOnly: false,
    timeOnly: true,
    useTimezone: true,
    dateStyle: DateTimeFormat.Short,
    timeStyle: DateTimeFormat.Short,
    locale: 'en-US',
    timezone: timezone ? timezone : 'UTC',
  })
}
// USER DATES END
